import React from "react";
import ReactDOM from "react-dom";
import RollbarCatchAll from "./rollbar";
import MeltwaterTeamsConnectorConfig from "./meltwaterTeamsConnector/MeltwaterTeamsConnectorConfig";



ReactDOM.render(
  <React.StrictMode>
    <RollbarCatchAll>
      <MeltwaterTeamsConnectorConfig />
    </RollbarCatchAll>
  </React.StrictMode>,
  document.getElementById("root")
);
