import React from 'react';
import pkg from '../package.json'
import { Provider, ErrorBoundary } from '@rollbar/react'; 
import Rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  enabled: !!process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_STAGE,
  code_version: pkg.version,
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export const rollbar = new Rollbar(rollbarConfig);
export const configureRollbarPerson = (context: microsoftTeams.Context, companyId?: String) => {
  if(context.userObjectId){
    rollbar.configure({
      payload: {
        person: {
          id: context.userObjectId,
          name: context.userPrincipalName,
          teamName: context.teamName,
          companyId: companyId
        }
      }
    })
  }
}

const RollbarCatchAll = (props:React.PropsWithChildren<{}>) => {
  return (
    <Provider instance={rollbar}> 
      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>
    </Provider>
  )
}

export default RollbarCatchAll;